<div
	(mouseenter)="onMouseEnter()"
	(mouseleave)="onMouseLeave()"
	[class.collapsed]="collapsed"
	[class.open]="collapsed && collapsedOpen$ | async"
	class="sidenav flex flex-col"
>
	<div class="sidenav-toolbar flex-none flex items-center">
		<img [src]="imageUrl$ | async" class="select-none flex-none w-50" />
		<h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">{{ title$ | async }}</h2>

		<button
			(click)="toggleCollapse()"
			*ngIf="showCollapsePin$ | async"
			class="w-8 h-8 marl-10 leading-none flex-none hidden lg:block"
			mat-icon-button
			type="button"
		>
			<mat-icon class="fs-14" *ngIf="!collapsed">radio_button_checked</mat-icon>
			<mat-icon class="fs-14" *ngIf="collapsed">radio_button_unchecked</mat-icon>
		</button>
	</div>

	<vex-scrollbar class="flex-auto">
		<div class="sidenav-items">
			<vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute" [item]="item" [level]="0"></vex-sidenav-item>
		</div>

		<div class="text-center mt-3 mb-5 copyright">
			&copy; {{ current_year }} Automotive Equity Management Limited
			<p class="sidenav-copy-right">
				Developed by
				<a href="https://devcentrehouse.eu/" target="_blank">Dev Centre House</a>
				(Ireland)
			</p>
			<div class="sidenav-copy-right">
				<a href="https://docs.mobilityequityx.com/regulatory-disclosures" target="_blank">Regulations</a>
				<span class="mx-2">|</span>
				<a href="https://app.gitbook.com/@automotive-equity-management/s/mex/" target="_blank">FAQ</a>
			</div>
		</div>
	</vex-scrollbar>
</div>
