import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsDialogsRoutingModule } from './confirmation-dialog-routing.module';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {FlexLayoutModule} from '@angular/flex-layout';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import { VexModule } from 'src/@vex/vex.module';


@NgModule({
  declarations: [ConfirmationDialogComponent],
    imports: [
        CommonModule,
        ComponentsDialogsRoutingModule,
        PageLayoutModule,
        SecondaryToolbarModule,
        BreadcrumbsModule,
        ContainerModule,
        MatButtonModule,
        VexModule,
        MatDialogModule,
        FlexLayoutModule
    ]
})
export class ComponentsDialogsModule { }
