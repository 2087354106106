import { Injectable } from '@angular/core';
import { PopoverService } from 'src/@vex/components/popover/popover.service';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	popoverRef: any;

	constructor(private popover: PopoverService) {}

	showPopover(originRef, component) {
		this.popoverRef = this.popover.open({
			content: component,
			origin: originRef,
			offsetY: 12,
			position: [
				{
					originX: 'center',
					originY: 'top',
					overlayX: 'center',
					overlayY: 'bottom',
				},
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top',
				},
			],
		});
	}

	closePopover() {
		this.popoverRef.close();
	}
}
