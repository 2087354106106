import { Observable, of, throwError } from 'rxjs';
import { retryWhen, delay, mergeMap, catchError } from 'rxjs/operators';

const DEFAULT_DELAY = 1000;
const DEFAULT_MAX_ENTRIES = 5;
const DEFAULT_BACKOFF = 1000;

const getErrorMessage = (maxRetry: number) => `Tried to call API ${maxRetry} times with no success, gave up.`;

export function delayedRetry(delayMs: number = DEFAULT_DELAY, maxRetry: number = DEFAULT_MAX_ENTRIES, backoffMs: number = DEFAULT_BACKOFF) {
	let retries = maxRetry;

	return (src: Observable<any>) => src.pipe(

		retryWhen((errors: Observable<any>) => errors.pipe(
			mergeMap(error => {

				if (retries-- > 0 && error.status === 500) {
					const backoffTime = delayMs + (maxRetry - retries) * backoffMs;
					return of(error).pipe(delay(backoffTime));
				}
				return throwError(getErrorMessage(maxRetry));
			})
		))
	);
}
