import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackService } from './snack.service';
import { LoaderService } from './loader.service';
import { delayedRetry } from './delayed-retry.operator';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private snackSrvc: SnackService,
		private loaderSrvc: LoaderService,
		private authSrvc: ApiService,
		private router: Router,
		private location: Location
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				if (err.status === 401) {
					this.snackSrvc.open(err.error.message || err.statusText);
					this.authSrvc.logout();
				} else if (err.status === 400) {
					let index = 0;
					for (var prop in err.error?.error) {
						if (index === 0) {
							this.snackSrvc.open(err.error?.error[prop][0] || err.statusText);
							index += 1;
						}
					}
				} else if (err.status === 404) {
					this.snackSrvc.open(err.error.message || err.statusText);
				} else if (err.status === 422) {
					if (err.error.error === 'insufficient_funds') {
						this.snackSrvc.open('Insufficient funds, please topup your account');
						this.location.back();
					}

					if (err.error.error === 'no_payment_method_set') {
						this.router.navigate(['/dashboard/billing/payment-method']);
					}

					if (err.error.error === 'inactive_subscription') {
						this.router.navigate(['/dashboard/subscription/confirm']);
					}
				} else if (err.status === 403) {
					if (this.router.url.includes('/dashboard')) {
						const role: Object = +JSON.parse(localStorage.getItem('user'))['role'];

						if (err.error.error === 'tracking_account_pro_required' && role.toString().includes('.1')) {
							this.snackSrvc.open('This feature requires an account upgrade');
							this.router.navigate(['/dashboard/market'], { queryParams: { upgrade: true } });
						} else if (err.error.error === 'tracking_account_pro_required' && !role.toString().includes('.1')) {
							this.snackSrvc.open('This feature requires an account upgrade');
							this.router.navigate(['/dashboard/subscription/confirm'], { queryParams: { upgrade: true , role: err.error.data.role} });
						} else if (err.error.error === 'tracking_account_required') {
							this.snackSrvc.open('This feature requires an account upgrade');
							this.router.navigate(['/dashboard/subscription/confirm'], { queryParams: { upgrade: true, role: err.error.data.role } });
						}
					}

					if (err.error.error === 'no_payment_method_set') {
						this.router.navigate(['/dashboard/billing/payment-method']);
					}

					if (err.error.error === 'inactive_subscription') {
						this.router.navigate(['/dashboard/subscription/confirm']);
					}
				} else if (err.status === 500) {
					this.snackSrvc.open(err.error.message || err.statusText);
				}
				this.loaderSrvc.setLoader(false);
				return throwError(err);
			}),
			delayedRetry()
		);
	}
}
