import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import { PageService } from 'src/shared/page.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/shared/auth.service';

@Component({
	selector: 'vex-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	constructor(
		private configService: ConfigService,
		private styleService: StyleService,
		private renderer: Renderer2,
		private platform: Platform,
		@Inject(DOCUMENT) private document: Document,
		@Inject(LOCALE_ID) private localeId: string,
		private route: ActivatedRoute,
		private pageSrvc: PageService,
		private titleSrvc: Title,
		private router: Router,
		private authSrvc: AuthService,
		private splashScreenService: SplashScreenService
	) {
		Settings.defaultLocale = this.localeId;

		if (this.platform.BLINK) {
			this.renderer.addClass(this.document.body, 'is-blink');
		}

		this.route.queryParamMap
			.pipe(map((queryParamMap) => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))))
			.subscribe((isRtl) => {
				this.document.body.dir = isRtl ? 'rtl' : 'ltr';
				this.configService.updateConfig({
					rtl: isRtl,
				});
			});

		this.route.queryParamMap
			.pipe(filter((queryParamMap) => queryParamMap.has('layout')))
			.subscribe((queryParamMap) => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

		this.route.queryParamMap
			.pipe(filter((queryParamMap) => queryParamMap.has('style')))
			.subscribe((queryParamMap) => this.styleService.setStyle(queryParamMap.get('style') as Style));

		this.route.queryParams.subscribe((queryparams) => {
			if (this.authSrvc.user()) {
				if (queryparams['notification.subject']) {
					if (queryparams['notification.subject'].includes('indexes')) {
						this.router.navigate(['/dashboard/market/' + queryparams['notification.subject'].split('.')[1]], { queryParams: { filter: 'week' } });
					}

					if (queryparams['notification.subject'].includes('assets')) {
						this.router.navigate(['/dashboard/portfolio/prices'], { fragment: queryparams['notification.subject'].split('assets.')[1] });
					}

					if (queryparams['notification.subject'].includes('futures.orders')) {
						this.router.navigate(['/dashboard/futures/my-orders'], { fragment: queryparams['notification.subject'].split('futures.orders.')[1] });
					}
				}
			}
		});

		this.pageSrvc.title.subscribe((name: string) => {
			this.titleSrvc.setTitle(name + ' | MEX');
		});
	}
}
