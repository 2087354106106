import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
	selector: 'img[onImageError]',
	host: {
		'(error)': 'updateUrl()',
		'(load)': 'load()',
		'[src]': 'src'
	}
})
export class ImageErrorDirective {

	constructor() { }

	@Input() src: string;
	@Input() onImageError: string;
	@HostBinding('class') className

	updateUrl() {
		this.src = this.onImageError;
	}
	load() {
		this.className = 'image-error';
	}
}
