import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare let window: any;

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor() { }

	virtual_credit: number;

	login(user: Object): void {
		localStorage.setItem('user', JSON.stringify(user));
	}

	logout(): void {
		window.Intercom('shutdown');
		localStorage.clear();
	}

	user(): Object {
		return JSON.parse(localStorage.getItem('user'));
	}

	isToken(): boolean {
		if (localStorage.getItem('user')) {
			this.bootIntercom();
			window.Intercom('update');
			return true;
		} else {
			window.Intercom('shutdown');
			return false;
		}
	}

	bootIntercom() {
		const userData: any = this.user();

		window.Intercom('boot', {
			app_id: environment.intercomAppID,
			user_id: userData.id,
			name: userData.first_name + ' ' + userData.last_name,
			email: userData.email,
			phone: userData.phone_code + ' ' + userData.phone,
		});
	}
}
