<div class="dropdown">
	<div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
		<div fxLayout="row" fxLayoutAlign="start center">
			<div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
				<mat-icon>person</mat-icon>
			</div>
			<div flex="auto">
				<div class="dropdown-heading">{{ first_name }} {{ last_name }}</div>
				<div class="dropdown-description">{{ email }}</div>
			</div>
		</div>
	</div>
	<div class="dropdown-content">
		<a
			[ngStyle]="
				(item.id === '2' && role % 1 === 0) ||
				(item.id === '2' && role === -1) ||
				(item.id === '4' && (role > 2.5 || role.toString().includes('.1'))) ||
				(item.id === '2' && role.toString().includes('.1'))
					? { display: 'none' }
					: { display: 'flex' }
			"
			(click)="close()"
			*ngFor="let item of items; trackBy: trackById"
			[hidden]="item.id !== '2'"
			[routerLink]="item.route"
			class="notification"
			fxLayout="row"
			fxLayoutAlign="start center"
			matRipple
		>
			<mat-icon [ngClass]="item.colorClass" class="notification-icon" fxFlex="none">{{ item.icon }}</mat-icon>
			<div fxFlex="auto">
				<div class="notification-label">{{ item.label }}</div>
				<div class="notification-description">{{ item.description }}</div>
			</div>
			<mat-icon class="notification-chevron" fxFlex="none">chevron_right</mat-icon>
		</a>

		<a
			(click)="openDialog(item.label)"
			*ngFor="let item of modalList"
			class="notification cursor-pointer"
			fxLayout="row"
			fxLayoutAlign="start center"
			matRipple
		>
			<mat-icon class="notification-icon" fxFlex="none">{{ item.icon }}</mat-icon>
			<div fxFlex="auto">
				<div class="notification-label">{{ item.label }}</div>
				<div class="notification-description">{{ item.description }}</div>
			</div>
			<mat-icon class="notification-chevron" fxFlex="none">chevron_right</mat-icon>
		</a>
	</div>

	<div class="dropdown-footer" fxLayout="row">
		<button mat-button (click)="logout()" color="accent" class="ml-auto">LOGOUT</button>
	</div>
</div>
