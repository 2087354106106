<a
	*ngIf="isLink(item) && !isFunction(item.route)"
	[fragment]="item.fragment"
	[routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
	[routerLink]="item.route"
	[queryParams]="item.queryParams"
	class="item"
	fxLayout="row"
	matRipple
	routerLinkActive="active"
>
	<mat-icon *ngIf="level === 0" class="item-icon" fxFlex="none">{{ item.icon }}</mat-icon>
	<span class="item-label" fxFlex="none">{{ item.label }}</span>
	<button *ngIf="item.need_upgrade" mat-chip-button class="item-upgrade ml-2">PRO</button>
	<span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="item-badge" fxFlex="none">{{ item.badge.value }}</span>
</a>

<div (click)="item.route()" *ngIf="isLink(item) && isFunction(item.route)" class="item" fxLayout="row" matRipple routerLinkActive="active">
	<mat-icon *ngIf="level === 0" class="item-icon" fxFlex="none">{{ item.icon }}</mat-icon>
	<span class="item-label" fxFlex="auto">{{ item.label }}</span>
	<button *ngIf="item.need_upgrade" mat-chip-button class="item-upgrade">PRO</button>
	<span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="item-badge" fxFlex="none">{{ item.badge.value }}</span>
</div>

<ng-container *ngIf="isDropdown(item)">
	<div (click)="toggleOpen()" [class.active]="isOpen || isActive" [class.open]="isOpen" class="item" fxLayout="row" matRipple>
		<mat-icon *ngIf="level === 0" class="item-icon" fxFlex="none">{{ item.icon }}</mat-icon>
		<span class="item-label" fxFlex="auto">{{ item.label }}</span>
		<span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="item-badge" fxFlex="none">{{ item.badge.value }}</span>
		<mat-icon [icIcon]="icKeyboardArrowRight" class="item-dropdown-icon" fxFlex="none"></mat-icon>
	</div>
	<div [@dropdown]="isOpen" class="item-dropdown">
		<vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
	</div>
</ng-container>

<ng-container *ngIf="isSubheading(item)">
	<div class="subheading">{{ item.label }}</div>
	<vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="0"></vex-sidenav-item>
</ng-container>
