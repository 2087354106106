<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
	<button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
		<mat-icon [icIcon]="icMenu"></mat-icon>
	</button>

	<a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 block" fxLayout="row" fxLayoutAlign="start center">
		<img class="w-8 select-none" src="assets/img/demo/logo.png" />
		<h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1>
	</a>

	<mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
		<button mat-menu-item>
			<mat-icon>person_add</mat-icon>
			<span>Add Contact</span>
		</button>

		<button mat-menu-item>
			<mat-icon>done_all</mat-icon>
			<span>Add Task</span>
		</button>

		<button mat-menu-item>
			<mat-icon>assignment_turned_in</mat-icon>
			<span>Add Project</span>
		</button>

		<button [matMenuTriggerFor]="documentMenu" mat-menu-item>
			<mat-icon>ballot</mat-icon>
			<span>Add Document</span>
		</button>
	</mat-menu>

	<mat-menu #documentMenu="matMenu">
		<button mat-menu-item>
			<mat-icon>description</mat-icon>
			<span>Add Quote</span>
		</button>

		<button mat-menu-item>
			<mat-icon>assignment</mat-icon>
			<span>Add Invoice</span>
		</button>

		<button mat-menu-item>
			<mat-icon>receipt</mat-icon>
			<span>Add Receipt</span>
		</button>
	</mat-menu>

	<div
		*ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
		[fxHide]="mobileQuery"
		class="px-gutter"
		fxFlex="none"
		fxLayout="row"
		fxLayoutAlign="start center"
	>
		<vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
	</div>

	<span fxFlex>
		<div [ngClass]="{ 'show-dots': isBreakPoint }" class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 toolbar-header">
			{{ headerTitle }}
		</div>

		<div [ngClass]="{ 'show-dots': isBreakPoint }" class="body-1 font-small leading-snug ltr:mr-3 rtl:ml-3 toolbar-header">
			{{ description }}
		</div>
	</span>

	<div class="-mx-1 flex items-center">
		<div class="px-1">
			<vex-toolbar-notifications></vex-toolbar-notifications>
		</div>

		<div class="px-1">
			<vex-toolbar-user></vex-toolbar-user>
		</div>
	</div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [fxHide]="mobileQuery"></vex-navigation>
