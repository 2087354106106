import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';

@Component({
	selector: 'vex-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss'],
	animations: [stagger80ms, scaleIn400ms, fadeInRight400ms, fadeInUp400ms],
})
export class ConfirmationDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { message: string; title?: string; confirmButtonText?: string; confirmButtonColor?: string; cancelButton?: boolean }
	) {
		if (!data.title) {
			data.title = 'Are you sure?';
		}

		if (!data.confirmButtonText) {
			data.confirmButtonText = 'YES';
		}
	}

	onConfirm(): void {
		this.dialogRef.close(true);
	}

	onDismiss(): void {
		this.dialogRef.close(false);
	}

	ngOnInit() {}
}
