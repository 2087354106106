import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { SnackService } from '../../../../shared/snack.service';
import { ApiService } from 'src/shared/api.service';
import { AuthService } from 'src/shared/auth.service';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormGroupDirective,
	NgForm,
	Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import icClose from '@iconify/icons-ic/twotone-close';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'dashboard-update-password',
	templateUrl: './dashboard-users-profile-password.component.html',
	styleUrls: ['./dashboard-users-profile-password.component.scss'],
	animations: [fadeInUp400ms],
})
export class DashboardUsersProfilePasswordComponent implements OnInit {
	form: FormGroup;

	loading = false;
	inputType = 'password';
	inputTypeConfirm = 'password';
	inputTypeCurrent = 'password';
	visible = false;
	visibleCurrent = false;
	matcher = new MyErrorStateMatcher();
	icClose = icClose;

	visibleConfirm = false;
	icVisibility = icVisibility;
	icVisibilityOff = icVisibilityOff;
	id: any;

	constructor(
		private fb: FormBuilder,
		private authSrvc: AuthService,
		private apiSrvc: ApiService,
		private snackbar: SnackService,
		private snack: SnackService,
		private cd: ChangeDetectorRef,
		private dialogRef: MatDialogRef<DashboardUsersProfilePasswordComponent>,
	) { }

	ngOnInit() {
		this.id = this.authSrvc.user()['id'];
		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group(
			{
				password: ['', [Validators.required]],

				new_password: [
					'',
					Validators.compose([
						Validators.required,
						Validators.pattern(
							'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$'
						),
					]),
				],
				passwordConfirm: ['', [Validators.required]],
			},
			{ validator: this.checkPasswords }
		);
	}

	checkPasswords(group: FormGroup) {
		// here we have the 'passwords' group
		const password = group.get('new_password').value;
		const confirmPassword = group.get('passwordConfirm').value;

		return password === confirmPassword ? null : { notSame: true };
	}

	send() {
		this.loading = true;
		if (this.loading === true) {
			this.snack.open('Please wait...');
		}
		if (this.form.invalid) {
			this.loading = false;
			return;
		}

		const data = {
			lang: 'en',
			old_password: this.form.get('password').value,
			new_password: this.form.get('new_password').value,
		};

		this.apiSrvc.updatePassword(data, this.id).subscribe((res) => {
			if (res.status === '1') {
				this.snackbar.open('Your password updated successfully');
				this.dialogRef.close();
			}
			this.loading = false;
		});
	}

	toggleVisibility(variable, type) {
		if (this[variable]) {
			this[type] = 'password';
			this[variable] = false;
			this.cd.markForCheck();
		} else {
			this[type] = 'text';
			this[variable] = true;
			this.cd.markForCheck();
		}
	}
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
		const invalidParent = !!(
			control?.parent?.invalid && control?.parent?.dirty
		);

		return invalidCtrl || invalidParent;
	}
}
