import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'productDescription',
})
export class ProductDescriptionPipe implements PipeTransform {
	constructor(private _sanitizer: DomSanitizer, private number: DecimalPipe) {}

	transform(element: any, ...args: unknown[]): unknown {
		let returnElem = '';

		if (element.brand_name) {
			returnElem += `<ng-container>${element.brand_name}&nbsp;&middot;&nbsp;</ng-container>`;
		}

		if (element.model_name) {
			returnElem += `<ng-container>${element.model_name}&nbsp;&middot;&nbsp;</ng-container>`;
		}

		if (element.body_type_name) {
			returnElem += `<ng-container>${element.body_type_name}&nbsp;&middot;&nbsp;</ng-container>`;
		}

		if (element.fuel_type_name) {
			returnElem += `<ng-container>${element.fuel_type_name}&nbsp;&middot;&nbsp;</ng-container>`;
		}

		if (element.age) {
			returnElem += `<ng-container>${element.age}&nbsp;Months old&nbsp;&middot;&nbsp;</ng-container>`;
		}

		if (element.country_name) {
			returnElem += `<ng-container>${element.country_name}&nbsp;</ng-container> `;
		}

		return this._sanitizer.bypassSecurityTrustHtml(returnElem);
	}
}
