import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelativeDateTimePipe } from './relative-date-time.pipe';
import { LocaltimePipe } from './localtime.pipe';


@NgModule({
    declarations: [RelativeDateTimePipe, LocaltimePipe],
    imports: [
        CommonModule
    ],
    exports: [RelativeDateTimePipe, LocaltimePipe]
})
export class RelativeDateTimeModule {
}
