import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { AuthGuard } from '../shared/auth.guard';
import { ConfirmAlertGuard } from 'src/shared/confirm-alert.guard';

const routes: VexRoutes = [
	{
		path: '',
		loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
	},
	{
		path: 'login',
		loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'register',
		loadChildren: () => import('./auth/register/register.module').then((m) => m.RegisterModule),
	},
	{
		path: 'register/verify/:email_token',
		loadChildren: () => import('./auth/register/register-complete/register-complete.module').then((m) => m.RegisterCompleteModule),
	},
	{
		path: 'register/pending',
		loadChildren: () => import('./auth/register/register-pending/register-pending.module').then((m) => m.RegisterPendingModule),
	},
	{
		path: 'forgot-password',
		loadChildren: () => import('./auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
	},
	{
		path: 'forgot-password/pending',
		loadChildren: () => import('./auth/forgot-password/forgot-password-pending/forgot-password-pending.module').then((m) => m.ForgotPasswordPendingModules),
	},
	{
		path: 'reset-password/complete/:token',
		loadChildren: () =>
			import('./auth/forgot-password/forgot-password-complete/forgot-password-complete.module').then((m) => m.ForgotPasswordCompleteModule),
	},
	{
		path: 'coming-soon',
		loadChildren: () => import('../shared/coming-soon/coming-soon.module').then((m) => m.ComingSoonModule),
	},
	{
		path: '',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				canActivate: [AuthGuard],
				children: [
					{
						path: 'market',
						loadChildren: () => import('./dashboard/dashboard-market/dashboard-market.module').then((m) => m.DashboardMarketModule),
					},
					{
						path: 'manage-users',
						loadChildren: () =>
							import('./dashboard/dashboard-manage-users/dashboard-manage-users.module').then((m) => m.DashboardManageUsersModule),
						data: {
							toolbarShadowEnabled: true,
							page: 'user',
						},
					},
					{
						path: 'manage-assets',
						loadChildren: () =>
							import('./dashboard/dashboard-manage-assets/dashboard-manage-assets.module').then((m) => m.DashboardManageAssetsModule),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'manage/future-products',
						loadChildren: () =>
							import('./dashboard/dashboard-manage-future-products/dashboard-manage-future-products.module').then(
								(m) => m.FutureManageProductsModule
							),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'manage/future-orders',
						loadChildren: () =>
							import('./dashboard/dashboard-manage-future-orders/dashboard-manage-future-orders.module').then(
								(m) => m.DashboardManageFutureOrdersModule
							),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'manage-competitions',
						loadChildren: () =>
							import('./dashboard/dashboard-manage-competitions/dashboard-manage-competitions.module').then(
								(m) => m.DashboardManageCompetitionsModule
							),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'logs',
						loadChildren: () => import('./dashboard/dashboard-logs/dashboard-logs.module').then((m) => m.DashboardLogsModule),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'billing',
						loadChildren: () => import('./dashboard/dashboard-billing/dashboard-billing.module').then((m) => m.DashboardBillingModule),
					},
					{
						path: 'manage-indexes',
						loadChildren: () =>
							import('./dashboard/dashboard-manage-indexes/dashboard-manage-indexes.module').then((m) => m.DashboardManageIndexesModule),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'change-password',
						loadChildren: () =>
							import('./dashboard/dashboard-users-profile/dashboard-users-profile-password/dashboard-users-profile-password.module').then(
								(m) => m.DashboardUsersProfilePasswordModule
							),
					},
					{
						path: 'portfolio',
						loadChildren: () => import('./dashboard/dashboard-portfolio/dashboard-portfolio.module').then((m) => m.DashboardPortfolioModule),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'watchlist',
						loadChildren: () => import('./dashboard/dashboard-watchlist/mex-my-watchlist.module').then((m) => m.DashboardWatchlistModule),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'subscription',
						loadChildren: () =>
							import('./dashboard/dashboard-subscription/dashboard-subscription.module').then((m) => m.DashboardSubscriptionModule),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'request-index',
						loadChildren: () =>
							import('./dashboard/dashboard-request-index/dashboard-request-index.module').then((m) => m.DashboardRequestIndexModule),
						data: {
							toolbarShadowEnabled: true,
						},
					},

					{
						path: 'futures',
						loadChildren: () =>
							import('./dashboard/dashboard-futures-trading/dashboard-futures-trading.module').then((m) => m.DashboardFuturesTradingModule),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'vehicles',
						loadChildren: () =>
							import('./dashboard/dashboard-manage-Vehicles/dashboard-manage-Vehicles.module').then((m) => m.DashboardManageVehiclesModule),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'mobility-economy',
						loadChildren: () =>
							import('./dashboard/dashboard-mobility-economy/mex-mobility-economy.module').then((m) => m.DashboardMobilityEconomyModule),
						data: {
							toolbarShadowEnabled: true,
						},
					},
					{
						path: 'users/profile',
						loadChildren: () =>
							import('./dashboard/dashboard-users-profile/dashboard-users-profile.module').then((m) => m.DashboardUsersProfileModule),
					},
					{
						path: 'users/organization',
						loadChildren: () =>
							import('./dashboard/dashboard-users-organization/dashboard-users-organization.module').then(
								(m) => m.DashboardUsersOrganizationModule
							),
					},
					{
						path: 'pages',
						canActivate: [AuthGuard],
						children: [
							{
								path: 'faq',
								loadChildren: () => import('./dashboard/dashboard-faq/dashboard-faq.module').then((m) => m.DashboardFaqModule),
							},
							{
								path: '404',
								loadChildren: () => import('src/shared/errors/error-404/error-404.module').then((m) => m.Error404Module),
							},
							{
								path: '500',
								loadChildren: () => import('src/shared/errors/error-500/error-500.module').then((m) => m.Error500Module),
							},
						],
					},
					{
						path: '',
						redirectTo: 'market',
						pathMatch: 'full',
					},
					{
						path: '**',
						loadChildren: () => import('src/shared/errors/error-404/error-404.module').then((m) => m.Error404Module),
					},
				],
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
			relativeLinkResolution: 'corrected',
			anchorScrolling: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
