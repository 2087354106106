import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ScrollService {
	constructor() {}

	scroll: { xpos: number; ypos: number; url: string; trigger: boolean };

	save(xpos: number, ypos: number, url: string, trigger: boolean = false) {
		this.scroll = {
			xpos: xpos === null ? this.scroll.xpos : xpos,
			ypos: ypos === null ? this.scroll.ypos : ypos,
			url: url === null ? this.scroll.url : url,
			trigger: trigger,
		};
	}

	fetch(): Object {
		return this.scroll;
	}
}
