import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { ConfigService } from '../../@vex/services/config.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';
import { SideBarSrvcService } from 'src/shared/side-bar-srvc.service';

@UntilDestroy()
@Component({
	selector: 'vex-custom-layout',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
	sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
	isFooterVisible$ = this.configService.config$.pipe(map((config) => config.footer.visible));
	isDesktop$ = this.layoutService.ltDesktop$;

	toolbarShadowEnabled$ = this.router.events.pipe(
		filter((event) => event instanceof NavigationEnd),
		startWith(null),
		map(() => checkRouterChildsData(this.router.routerState.root.snapshot, (data) => data.toolbarShadowEnabled))
	);

	@ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

	constructor(
		private layoutService: LayoutService,
		private configService: ConfigService,
		private router: Router,
		private sideBarSrvcService: SideBarSrvcService
	) {}

	ngOnInit() {
		this.sideBarSrvcService.loadSidebar();
	}
}
