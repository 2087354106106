<div class="dropdown">
	<div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
		<div fxLayout="row" fxLayoutAlign="start center">
			<div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
				<mat-icon>notifications</mat-icon>
			</div>
			<div flex="auto">
				<div class="dropdown-heading">Notifications</div>
				<div *ngIf="unreadCount > 0; else notify" class="dropdown-subheading">You have {{ unreadCount }} new
					notifications.</div>
				<ng-template #notify>
					<div class="dropdown-subheading">You're all caught up</div>
				</ng-template>
			</div>
		</div>
		<button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
			<mat-icon class="notifications-header-icon">settings</mat-icon>
		</button>
	</div>

	<div class="dropdown-content">
		<a *ngFor="let notification of notifications; let i = index" [class.read]="notification.read === '1'"
			[routerLink]="getRoute(notification)" class="notification" fxLayout="row" fxLayoutAlign="start center"
			matRipple>
			<div fxFlex="auto" (click)="readNotification(i, notification)">
				<div [ngClass]="notification.read === '0' ? 'notification-label' : 'notification-label'">{{
					notification.title }}</div>
				<div class="notification-description">{{ notification.created_at | date: 'dd/MM/yyyy, h:mm a' }}</div>
			</div>
			<mat-icon class="notification-chevron" fxFlex="none">chevron_right</mat-icon>
		</a>
	</div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
	<button (click)="readAll()" mat-menu-item>
		<mat-icon>clear_all</mat-icon>
		<span>Mark all as read</span>
	</button>
	<button (click)="clearAll()" mat-menu-item>
		<mat-icon>delete_sweep</mat-icon>
		<span>Clear all</span>
	</button>
</mat-menu>