import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { IconModule } from '@visurel/iconify-angular';
import { DashboardUsersProfilePasswordRoutingModule } from './dashboard-users-profile-password-routing.module';
import { DashboardUsersProfilePasswordComponent } from './dashboard-users-profile-password.component';
import { MaterialModule } from 'src/shared/material/material.module';
import { VexModule } from 'src/@vex/vex.module';


@NgModule({
	declarations: [DashboardUsersProfilePasswordComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		ReactiveFormsModule,
		MatInputModule,
		MatButtonModule,
		IconModule,
		DashboardUsersProfilePasswordRoutingModule,
		MaterialModule,
		VexModule,

	]
})
export class DashboardUsersProfilePasswordModule { }
