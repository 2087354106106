<vex-page-layout @stagger>
	<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

		<h2 fxFlex="auto"> Change Email</h2>

		<button class="text-secondary" mat-dialog-close mat-icon-button type="button">
			<mat-icon [icIcon]="icClose"></mat-icon>
		</button>
	</div>

	<mat-divider class="-mx-6 text-border"></mat-divider>

	<form [formGroup]="form" class="width-100" (keyup.enter)="submitEnter($event)">
		<div class="example-container">
			<mat-form-field appearance="standard" class="w-full">
				<mat-label>Old email:</mat-label>
				<input disabled="true" matInput [value]="email" type="text">
			</mat-form-field>

			<mat-form-field appearance="standard" class="w-full">
				<mat-label>New Email</mat-label>
				<input matInput type="text" formControlName="email">
				<mat-error *ngIf="form.get('email').hasError('required')">Valid email address is required
				</mat-error>
			</mat-form-field>
		</div>
		<div class="w-full mb-3">
			<mat-dialog-actions align="end">
				<button class="mar-r15" mat-button mat-dialog-close type="button">CANCEL</button>
				<button color="accent" [disabled]="!form.valid" (click)="send()" mat-raised-button
					type="submit">SAVE</button>
			</mat-dialog-actions>
		</div>
	</form>
</vex-page-layout>