import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/shared/api.service';
import { NotificationService } from 'src/shared/notification.service';

@Component({
	selector: 'vex-toolbar-notifications-dropdown',
	templateUrl: './toolbar-notifications-dropdown.component.html',
	styleUrls: ['./toolbar-notifications-dropdown.component.scss'],
})
export class ToolbarNotificationsDropdownComponent implements OnInit, OnDestroy {
	unreadCount: any;

	emailNotificationStatus = true;

	notifications: any[];
	notification$: Subscription;
	constructor(private apiSrvc: ApiService, private notificationSrvc: NotificationService) {}

	ngOnInit() {
		this.notification$ = this.apiSrvc.notificationData$.subscribe((res) => {
			this.notifications = res;
			this.unreadCount = this.notifications.reduce((count, item) => count + (item.read === '0'), 0);
		});
	}

	getRoute(notification) {
		const subject = notification.subject.split('.');

		let route = [];
		switch (subject[0]) {
			case 'indexes':
				route = [`/dashboard/market/${subject[1]}`];
				break;

			case 'assets':
				route = [`/dashboard/portfolio/prices`];
				break;

			case 'futures':
				route = [`/dashboard/futures/products/${subject[2]}`];
				break;

			default:
				route = ['/dashboard/market'];
				break;
		}

		return route;
	}

	notificationAction(status) {
		this.emailNotificationStatus = status;
	}

	readAll() {
		this.apiSrvc.readAllNotification().subscribe((res) => {
			if (res['status'] === '1') {
				for (let x = 0; x < this.notifications.length; x++) {
					const element = this.notifications[x];
					element['read'] = '1';
				}
				this.apiSrvc.notificationData$.next(this.notifications);
				this.unreadCount = 0;
				this.notificationSrvc.closePopover();
			}
		});
	}

	clearAll() {
		this.apiSrvc.notificationData$.next([]);
		this.apiSrvc.clearAllNotification().subscribe((res) => {
			if (res['status'] === '1') {
				this.unreadCount = 0;
				this.notificationSrvc.closePopover();
			}
		});
	}

	readNotification(index, notification) {
		this.notifications[index]['read'] = '1';
		this.unreadCount -= 1;
		this.apiSrvc.notificationData$.next(this.notifications);
		this.apiSrvc.readNotification(notification.id).subscribe((res) => {
			if (res['status'] === '1') {
				this.notificationSrvc.closePopover();
			}
		});
	}
	ngOnDestroy(): void {
		this.notification$.unsubscribe();
	}
}
