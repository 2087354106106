import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	notificationData$ = new BehaviorSubject<any[]>([]);

	constructor(private http: HttpClient, private router: Router, public auth: AuthService) { }

	HttpParamsFromJSON(json: Object): HttpParams {
		let params = new HttpParams();

		Object.entries(json).forEach(([key, value]) => {
			if (key && value) {
				params = params.set(key, value);
			}
		});

		return params;
	}

	login(body) {
		return this.http.post<any>(environment.api_url + '/login', body).pipe(
			map((user) => {
				if (user && user.status === '1') {
					this.auth.login(user.data);
				}
				return user;
			})
		);
	}

	register(data) {
		return this.http.post<any>(environment.api_url + '/register', data);
	}

	completeRegistrationEmail(data) {
		return this.http.post<any>(environment.api_url + '/register/verify', data);
	}

	ForgotPassword(data) {
		return this.http.post<any>(environment.api_url + '/users/password/reset', data);
	}

	completeForgotPasswordEmail(data) {
		return this.http.post<any>(environment.api_url + '/users/password/reset/complete', data).pipe(
			map((user) => {
				if (user && user.status === '1') {
					this.auth.login(user.data);
				}
				return user;
			})
		);
	}

	completeChangeEmail(data, id) {
		return this.http.post<any>(environment.api_url + '/users' + `/${id}/` + 'email/verify', data);
	}
	updatePassword(body, id) {
		return this.http.put<any>(environment.api_url + '/users' + `/${id}` + '/password', body);
	}

	logout() {
		this.auth.logout();
		this.router.navigate(['/login']);
	}

	getUsersList(body) {
		return this.http.get(environment.api_url + '/users', {
			params: this.HttpParamsFromJSON(body),
		});
	}

	getSuggestedIndex(id) {
		return this.http.get(environment.api_url + '/assets' + `/${id}/` + 'attach/suggestions');
	}

	attachAsset(body) {
		return this.http.post<any>(environment.api_url + '/assets' + `/${body.asset_id}/` + 'attach', body);
	}

	getIndexList(body) {
		return this.http.get(environment.api_url + '/indexes', {
			params: this.HttpParamsFromJSON(body),
		});
	}

	getIndexById(id) {
		return this.http.get(`${environment.api_url}/indexes/${id}`);
	}

	deleteIndex(id) {
		return this.http.delete(environment.api_url + '/indexes' + `/${id}`);
	}

	postIndex(body) {
		return this.http.post(environment.api_url + '/indexes', body);
	}

	getFilters() {
		return this.http.get(environment.api_url + '/filters');
	}

	getUser(id) {
		return this.http.get(environment.api_url + '/users' + `/${id}`);
	}

	manageUser(id) {
		return this.http.get(environment.api_url + '/manage/users' + `/${id}`);
	}

	updateUser(body, id) {
		return this.http.put(environment.api_url + '/users' + `/${id}`, body);
	}

	deleteUser(id) {
		return this.http.delete(environment.api_url + '/users' + `/${id}`);
	}

	updateUserRole(body, id) {
		return this.http.put(environment.api_url + '/users' + `/${id}/` + 'role', body);
	}

	getUsers(body) {
		return this.http.get(environment.api_url + '/users', {
			params: this.HttpParamsFromJSON(body),
		});
	}

	getNews(body) {
		return this.http.get(environment.api_url + '/news', {
			params: this.HttpParamsFromJSON(body),
		});
	}

	changeEmail(body, id) {
		return this.http.put(environment.api_url + '/users' + `/${id}/` + 'email', body);
	}

	filterCountries(body) {
		return this.http.get(environment.api_url + '/filters/countries', {
			params: this.HttpParamsFromJSON(body),
		});
	}

	filtersFuturesMaturities() {
		return this.http.get(environment.api_url + '/filters/futures/maturities');
	}

	getTwitterTweets() {
		return this.http.get(environment.api_url + '/twitter/users/tweets');
	}

	attachBillingMethod(data) {
		return this.http.post(environment.api_url + '/billing/methods', data);
	}

	getBillingMethod(search) {
		return this.http.get(environment.api_url + `/billing/methods?user_id=${search.userId}`);
	}

	postAssets(data) {
		return this.http.post(environment.api_url + '/assets', data);
	}

	getTrends(params) {
		return this.http.get(environment.api_url + `/trends/indexes`, {
			params: this.HttpParamsFromJSON(params),
		});
	}

	getAssets(params) {
		return this.http.get(environment.api_url + `/assets`, {
			params: this.HttpParamsFromJSON(params),
		});
	}

	getAssetsDetails(id) {
		return this.http.get(environment.api_url + `/assets/${id}`);
	}

	makeAssetsActive(id) {
		return this.http.post(environment.api_url + `/assets/${id}/active`, {});
	}

	makeAssetsInActive(id) {
		return this.http.post(environment.api_url + `/assets/${id}/inactive`, {});
	}

	deleteAssets(id) {
		return this.http.delete(environment.api_url + `/assets/${id}`);
	}

	getAllAssets() {
		return this.http.get(environment.api_url + `/assets`);
	}

	createUser(data) {
		return this.http.post(environment.api_url + '/users', data);
	}

	completeProfile(data) {
		return this.http.post(environment.api_url + '/register/complete', data).pipe(
			map((user: any) => {
				if (user && user.status === '1') {
					this.auth.login(user.data);
				}
				return user;
			})
		);
	}

	getComments(params) {
		return this.http.get(environment.api_url + `/comments`, {
			params: this.HttpParamsFromJSON(params),
		});
	}

	postComment(data) {
		return this.http.post(environment.api_url + '/comments', data);
	}

	getWatchlist(params) {
		return this.http.get(environment.api_url + `/watchlist`, {
			params: this.HttpParamsFromJSON(params),
		});
	}

	postWatchlist(data) {
		return this.http.post(environment.api_url + '/watchlist', data);
	}

	deleteWatchlist(id) {
		return this.http.delete(environment.api_url + '/watchlist' + `/${id}`);
	}

	updateRole(data, id) {
		return this.http.put<any>(environment.api_url + '/users' + `/${id}` + '/role', data);
	}

	activeIndex(id) {
		return this.http.post<any>(environment.api_url + '/indexes/' + `${id}` + '/active', {});
	}

	inActiveIndex(id) {
		return this.http.post<any>(environment.api_url + '/indexes/' + `${id}` + '/inactive', {});
	}

	getIndexPrice(id) {
		return this.http.get<any>(environment.api_url + '/indexes/' + `${id}` + '/prices');
	}

	postIndexPrice(id, data) {
		return this.http.post<any>(environment.api_url + '/indexes/' + `${id}` + '/prices', data);
	}

	deleteIndexPrice(id) {
		return this.http.delete<any>(environment.api_url + '/indexes/' + `${id}` + '/prices');
	}

	generateName(id) {
		return this.http.post<any>(environment.api_url + '/indexes/' + `${id}` + '/generate_name', {});
	}

	updateIndexName(id, data) {
		return this.http.put<any>(environment.api_url + '/indexes/' + `${id}`, data);
	}

	getLogs() {
		return this.http.get(environment.api_url + `/logs`);
	}

	getFutureProducts(data?) {
		return this.http.get(environment.api_url + `/futures/products`, {
			params: this.HttpParamsFromJSON(data),
		});
	}

	getFutureTrendProducts(body) {
		return this.http.get(environment.api_url + `/trends/futures/products`, {
			params: this.HttpParamsFromJSON(body),
		});
	}

	postFutureSettings(body) {
		return this.http.post<any>(environment.api_url + `/futures/products`, body);
	}

	createFuturesOrder(body) {
		return this.http.post<any>(environment.api_url + `/futures/orders`, body);
	}

	getFuturesOrders(body) {
		return this.http.get(environment.api_url + `/futures/orders`, {
			params: this.HttpParamsFromJSON(body),
		});
	}

	getFuturesProductById(id) {
		return this.http.get(environment.api_url + `/futures/products/${id}`);
	}

	matchFuturesOrder(id, body) {
		return this.http.post<any>(environment.api_url + `/futures/orders/${id}/match`, body);
	}

	cancelFuturesOrder(id, body) {
		return this.http.post<any>(environment.api_url + `/futures/orders/${id}/cancel`, body);
	}

	cancelFuturesOrderByAdmin(id, user_id, body) {
		return this.http.post<any>(environment.api_url + `/futures/orders/${id}/cancel/users/${user_id}`, body);
	}

	liquidateOrder(id, body) {
		return this.http.post<any>(environment.api_url + `/futures/orders/${id}/liquidate`, body);
	}

	liquidateOrderByAdmin(id, user_id, body) {
		return this.http.post<any>(environment.api_url + `/futures/orders/${id}/liquidate/users/${user_id}`, body);
	}

	rollOverOrder(id, body) {
		return this.http.post<any>(environment.api_url + `/futures/orders/${id}/roll_over`, body);
	}

	rollOverOrderByAdmin(id, user_id, body) {
		return this.http.post<any>(environment.api_url + `/futures/orders/${id}/roll_over/users/${user_id}`, body);
	}

	cancelLiquidate(id) {
		return this.http.post(environment.api_url + `/futures/orders/${id}/liquidate/cancel`, {});
	}

	cancelLiquidateByAdmin(id, user_id) {
		return this.http.post(environment.api_url + `/futures/orders/${id}/liquidate/cancel/users/${user_id}`, {});
	}

	getVirtualCreditBalance(id?) {
		var userId: any;

		if (!this.auth.user()) {
			return;
		}

		if (id) {
			userId = id;
		} else {
			userId = this.auth.user()['id'];
		}

		this.http.get(environment.api_url + `/users/${userId}/virtual_credit`).subscribe((res) => {
			if (res['status'] === '1') {
				this.auth.virtual_credit = res['data']['virtual_credit'];
			}
		});
	}

	getVirtualCredit(id) {
		return this.http.get(environment.api_url + `/users/${id}/virtual_credit`);
	}

	updateVirtualCredit(id, data) {
		return this.http.put(environment.api_url + `/users/${id}/virtual_credit`, data);
	}

	addBrands(body) {
		return this.http.post(environment.api_url + `/brands`, body);
	}

	getBrands() {
		return this.http.get(environment.api_url + `/brands`);
	}

	putBrands(brandId, body) {
		return this.http.put(environment.api_url + `/brands/${brandId}`, body);
	}

	deleteBrands(brandId) {
		return this.http.delete(environment.api_url + `/brands/${brandId}`);
	}

	activateBrand(brandId) {
		return this.http.post(environment.api_url + `/brands/${brandId}/active`, {});
	}

	inActivateBrand(brandId) {
		return this.http.post(environment.api_url + `/brands/${brandId}/inactive`, {});
	}

	addModels(body) {
		return this.http.post(environment.api_url + `/models`, body);
	}

	getModels(data) {
		return this.http.get(environment.api_url + `/models`, {
			params: this.HttpParamsFromJSON(data),
		});
	}

	putModels(modelId, body) {
		return this.http.put(environment.api_url + `/models/${modelId}`, body);
	}

	deleteModels(modelId) {
		return this.http.delete(environment.api_url + `/models/${modelId}`);
	}

	activateModels(modelId) {
		return this.http.post(environment.api_url + `/models/${modelId}/active`, {});
	}

	inActivateModels(modelId) {
		return this.http.post(environment.api_url + `/models/${modelId}/inactive`, {});
	}

	createStripeSetupIntent() {
		return this.http.post(environment.api_url + `/billing/methods/setup_intent`, undefined);
	}

	verifyCoupon(body) {
		return this.http.post(environment.api_url + `/billing/coupons/validity`, body);
	}

	publicIndexes(body) {
		return this.http.get(environment.api_url + `/indexes/public`, {
			params: this.HttpParamsFromJSON(body),
		});
	}

	getBillingSubscription() {
		return this.http.get(environment.api_url + '/billing/subscriptions');
	}

	getDraftBillingSubscription() {
		return this.http.post(environment.api_url + '/billing/subscriptions/draft', undefined);
	}

	getUpgradeBillingSubscription(data) {
		return this.http.get(environment.api_url + '/billing/subscriptions/upgrades', {
			params: this.HttpParamsFromJSON(data)
		});
	}

	cancelBillingSubscription() {
		return this.http.delete(environment.api_url + '/billing/subscriptions', undefined);
	}

	resumeBillingSubscription() {
		return this.http.post(environment.api_url + '/billing/subscriptions/resume', undefined);
	}

	confirmSubscription() {
		return this.http.post(environment.api_url + '/billing/subscriptions', undefined);
	}

	upgradeSubscription(body) {
		return this.http.post(environment.api_url + '/billing/subscriptions/upgrades', body);
	}

	getNotifications() {
		return this.http.get(environment.api_url + '/notifications').pipe(
			map((notification) => {
				if (notification && notification['status'] === '1') {
					this.notificationData$.next(notification['data']);
				}
				return notification;
			})
		);
	}

	readNotification(id) {
		return this.http.post(environment.api_url + `/notifications/${id}/read`, undefined);
	}

	readAllNotification() {
		return this.http.post(environment.api_url + '/notifications/read_all', undefined);
	}

	clearAllNotification() {
		return this.http.delete(environment.api_url + '/notifications', undefined);
	}

	broadCastNotification(id) {
		return this.http.post(environment.api_url + `/indexes/${id}/broadcast`, undefined);
	}

	setFuturesProductActive(body) {
		return this.http.post(environment.api_url + `/futures/products/${body?.id}/active`, undefined);
	}

	setFuturesProductInactive(body) {
		return this.http.post(environment.api_url + `/futures/products/${body?.id}/inactive`, undefined);
	}

	getCompetitions() {
		return this.http.get(environment.api_url + '/competitions');
	}

	getPublicCompetitions() {
		return this.http.get(environment.api_url + '/competitions/public');
	}

	updateCompetition(id, data) {
		return this.http.put(environment.api_url + '/competitions/' + `${id}`, data);
	}

	createCompetition(data) {
		return this.http.post(environment.api_url + '/competitions', data);
	}

	deleteCompetition(id) {
		return this.http.delete(environment.api_url + '/competitions/' + `${id}`);
	}

	getCompetitionUsers(id) {
		return this.http.get(environment.api_url + '/competitions/' + `${id}` + '/users');
	}

	deleteCompetitionUser(id, user_id) {
		return this.http.delete(environment.api_url + '/competitions/' + `${id}` + '/users' + `${user_id}`);
	}

	activateCompetition(id) {
		return this.http.post(environment.api_url + '/competitions/' + `${id}` + '/active', undefined);
	}

	deactivateCompetition(id) {
		return this.http.post(environment.api_url + '/competitions/' + `${id}` + '/inactive', undefined);
	}

	getCompetitionUserEmails(id) {
		return this.http.get(environment.api_url + '/competitions/' + `${id}` + '/users/excel');
	}

	updateIndexForMacro(id, body) {
		return this.http.post<any>(environment.api_url + '/indexes/' + `${id}` + '/macro', body);
	}
}
