import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProgressBarModule } from '../components/progress-bar/progress-bar.module';
import { SearchModule } from '../components/search/search.module';
import { ToolbarNotificationsDropdownComponent } from './toolbar/toolbar-notifications/toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';

@NgModule({
    declarations: [LayoutComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatSidenavModule,
        ProgressBarModule,
        SearchModule
    ],
    entryComponents:[ToolbarNotificationsDropdownComponent],
    exports: [LayoutComponent]
})

export class LayoutModule {
}
