import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import icHome from '@iconify/icons-ic/twotone-home';
import { ScrollService } from 'src/shared/scroll.service';
import { trackByValue } from '../../utils/track-by';

@Component({
	selector: 'vex-breadcrumbs',
	template: `
		<div class="flex items-center mt-2">
			<vex-breadcrumb>
				<a [routerLink]="routerUrl()" [queryParams]="params ? { 'filter.pagesize': pageSize, 'filter.pageindex': pageIndex } : null">
					<mat-icon inline="true" style="font-size: 20px">home</mat-icon>
				</a>
			</vex-breadcrumb>
			<ng-container *ngFor="let crumb of crumbs; trackBy: trackByValue">
				<div class="w-1 h-1 bg-gray rounded-full ltr:mr-2 rtl:ml-2"></div>
				<vex-breadcrumb>
					<a href="javascript:;">{{ crumb }}</a>
				</vex-breadcrumb>
			</ng-container>
		</div>
	`,
})
export class BreadcrumbsComponent implements OnInit {
	constructor(private router: Router, private scroll: ScrollService) {}

	@Input() crumbs: string[] = [];

	trackByValue = trackByValue;
	icHome = icHome;

	link: string;
	url: string;
	params: any;
	pageSize: string = '';
	pageIndex: string = '';

	routerUrl(): string {
		if (this.url) {
			return this.url;
		} else if (!this.url && this.link) {
			return this.link;
		} else if (!this.url && !this.link) {
			return '/dashboard/market';
		}
	}

	ngOnInit() {
		const _scroll = this.scroll.fetch();
		if (_scroll?.['url']) {
			this.link = _scroll['url'];

			if (this.link.includes('pagesize' || 'pageindex')) {
				const linkSplitter: Array<any> = this.link.split('?', 2);

				this.params = linkSplitter[linkSplitter.length - 1].split('&', 2);
				this.url = linkSplitter[0];
				this.params.forEach((param) => {
					var paramValue = param.split('=');
					if (paramValue[0].toLowerCase().includes('pagesize')) {
						this.pageSize = paramValue[1];
					}

					if (paramValue[0].toLowerCase().includes('pageindex')) {
						this.pageIndex = paramValue[1];
					}
				});
			}
		}

		this.router.events.subscribe((event: NavigationStart) => {
			event.navigationTrigger = 'popstate';
		});
	}
}
