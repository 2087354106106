import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { loginType } from './models/userType';
import { AuthService } from './auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from './data.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private authSrvc: AuthService, private router: Router, private snack: MatSnackBar, private data: DataService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		var role: Object = localStorage.getItem('user') ? +JSON.parse(localStorage.getItem('user'))['role'] : '';
		var competition_user: number = localStorage.getItem('user') ? +JSON.parse(localStorage.getItem('user'))['competition_user'] : 0;

		loginType.forEach((item) => {
			if (this.authSrvc.user() && item.type === this.authSrvc.user()['role']) {
				return;
			}
		});

		if (this.authSrvc.isToken()) {
			// Below condition will stop competition users to access non-permitted tabs.
			if (
				(state.url.includes('/portfolio') ||
					state.url.includes('/request-index') ||
					state.url.includes('/manage-indexes') ||
					state.url.includes('/manage-users') ||
					state.url.includes('/manage-assets') ||
					state.url.includes('/vehicles') ||
					state.url.includes('/future-products') ||
					state.url.includes('/future-orders')) &&
				competition_user > 0
			) {
				this.router.navigateByUrl('/dashboard');
				return false;
			}

			// Below condition will stop macro user to access organization page.
			if ((state.url.includes('/organization')) && role === -1) {
				this.router.navigateByUrl('/dashboard');
				return false;
			}

			// Below condition will stop non admin use to access manage tab.
			if (
				(state.url.includes('/manage-indexes') ||
					state.url.includes('/manage-users') ||
					state.url.includes('/manage-assets') ||
					state.url.includes('/vehicles') ||
					state.url.includes('/future-products') ||
					state.url.includes('/future-orders')) &&
				role < 3.0
			) {
				this.router.navigateByUrl('/dashboard');
				return false;
			}

			if ((state.url.includes('/billing') || state.url.includes('/subscription')) && role >= 3.0) {
				this.router.navigateByUrl('/dashboard');
				return false;
			}

			return true;
		} else {
			if (state.url.includes('notification.subject')) {
				this.data.navigateByNotificationUrl(state.url);
			}
		}

		this.router.navigate(['/login']);
		return false;
	}
}
