import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardModule } from './dashboard/dashboard.module';
import { TokenInterceptor } from '../shared/token.interceptor';
import { PageLayoutModule } from '../@vex/components/page-layout/page-layout.module';
import { BreadcrumbsModule } from '../@vex/components/breadcrumbs/breadcrumbs.module';
import { IconModule } from '@visurel/iconify-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ComponentsDialogsModule } from 'src/shared/confirmation-dialog/confirmation-dialog.module';
import { MaterialModule } from 'src/shared/material/material.module';
import { ErrorInterceptor } from 'src/shared/error.interceptor';
import { LangInterceptor } from 'src/shared/lang.interceptor';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		VexModule,
		DashboardModule,
		PageLayoutModule,
		BreadcrumbsModule,
		IconModule,
		FlexLayoutModule,
		ComponentsDialogsModule,
		MaterialModule,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
