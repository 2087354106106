import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[InputCurrency]',
})
export class InputCurrencyDirective {
	private regexString(max?: number) {
		const maxStr = max ? `{0,${max}}` : `+`;
		return `^(\\d${maxStr}(\\.\\d{0,2})?|\\.\\d{0,2})$`;
	}
	private digitRegex: RegExp;
	private setRegex(maxDigits?: number) {
		this.digitRegex = new RegExp(this.regexString(maxDigits), 'g');
	}
	@Input()
	set maxDigits(maxDigits: number) {
		this.setRegex(maxDigits);
	}

	private el: HTMLInputElement;

	constructor(private elementRef: ElementRef, private currencyPipe: CurrencyPipe) {
		this.el = this.elementRef.nativeElement;
		this.setRegex();
	}

	ngOnInit() {
		this.el.value = this.currencyPipe.transform(this.el.value, 'USD');
	}

	@HostListener('focus', ['$event.target.value'])
	onFocus(value) {
		this.el.value = value.replace(/[^0-9.]+/g, '');
	}

	@HostListener('blur', ['$event.target.value'])
	onBlur(value) {
		this.el.value = this.currencyPipe.transform(value, 'USD');
	}

	private lastValid = '';
	@HostListener('input', ['$event'])
	onInput(event) {
		// const cleanValue = (event.target.value.match(this.digitRegex) || []).join('')
		// if (cleanValue || !event.target.value)
		// 	this.lastValid = cleanValue
		// this.el.value = cleanValue || this.lastValid
	}
}
