import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'vex-page-layout',
    template: '<ng-content></ng-content>',
    host: {
        class: 'vex-page-layout'
    },
    styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent {

    @Input() mode: 'card' | 'simple' = 'simple';

    constructor() { }

    @HostBinding('class.vex-page-layout-card')
    get isCard() {
        return this.mode === 'card';
    }

    @HostBinding('class.vex-page-layout-simple')
    get isSimple() {
        return this.mode === 'simple';
    }

}
