<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

	<h2 fxFlex="auto"> Change Password</h2>

	<button class="text-secondary" mat-dialog-close mat-icon-button type="button">
		<mat-icon [icIcon]="icClose"></mat-icon>
	</button>
</div>

<mat-divider class="-mx-6 text-border"></mat-divider>

<form [formGroup]="form" class="width-100" (ngSubmit)="send()">
	<div class="example-container">
		<mat-form-field appearance="standard">
			<input placeholder="Current Password" class="pb-6" formControlName="password" matInput
				[type]="inputTypeCurrent">
			<button disableRipple (click)="toggleVisibility('visibleCurrent', 'inputTypeCurrent')" mat-icon-button
				matSuffix matTooltip="Toggle Visibility" type="button">
				<mat-icon class="pass-icon" color="primary" *ngIf="visibleCurrent">visibility
				</mat-icon>
				<mat-icon class="pass-icon" color="primary" *ngIf="!visibleCurrent">
					visibility_off
				</mat-icon>
			</button>

			<mat-error *ngIf="form.get('password').hasError('required')">We need a Current
				Password.
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="standard">
			<input placeholder="New Password" formControlName="new_password" class="pb-6" matInput [type]="inputType">
			<button disableRipple (click)="toggleVisibility('visible', 'inputType')" mat-icon-button matSuffix
				matTooltip="Toggle Visibility" type="button">
				<mat-icon class="pass-icon" color="primary" *ngIf="visible">visibility
				</mat-icon>
				<mat-icon class="pass-icon" color="primary" *ngIf="!visible">visibility_off
				</mat-icon>
			</button>

			<mat-error *ngIf="form.get('new_password').hasError('required')">We need a password.
			</mat-error>
			<mat-error *ngIf="form.get('new_password').hasError('pattern')">Passwords are
				required
				to follow all security standards
			</mat-error>
		</mat-form-field>


		<mat-form-field appearance="standard">
			<input [errorStateMatcher]="matcher" placeholder="Confirm Password" formControlName="passwordConfirm"
				class="pb-6" matInput [type]="inputTypeConfirm">
			<button disableRipple (click)="toggleVisibility('visibleConfirm','inputTypeConfirm')" mat-icon-button
				matSuffix matTooltip="Toggle Visibility" type="button">
				<mat-icon class="pass-icon" color="primary" *ngIf="visibleConfirm">visibility
				</mat-icon>
				<mat-icon class="pass-icon" color="primary" *ngIf="!visibleConfirm">
					visibility_off
				</mat-icon>
			</button>
			<mat-hint>Please repeat your password from above</mat-hint>
			<mat-error *ngIf="form.get('passwordConfirm').hasError('required')">We need a
				Confirm Password.
			</mat-error>

			<mat-error *ngIf="form.hasError('notSame')">Passwords
				do not match
			</mat-error>
		</mat-form-field>
	</div>
	<div class="w-full mb-3">
		<mat-dialog-actions align="end">
			<button class="mar-r15" mat-button mat-dialog-close type="button">CANCEL</button>
			<button color="accent" [disabled]="!form.valid" (click)="send()" mat-raised-button
				type="submit">SAVE</button>
		</mat-dialog-actions>
	</div>
</form>