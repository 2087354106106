import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from './loader.service';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	token: any;

	constructor(private snakbar: MatSnackBar, private loaderSrvc: LoaderService, private authSrvc: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!navigator.onLine) {
			this.snakbar.open('Please connect to the internet first', '', { duration: 5000 });
			return;
		}

		const currentUser = this.authSrvc.user();
		this.loaderSrvc.setLoader(true);
		if (currentUser) {
			this.token = currentUser['access_token'];
		}

		if (this.token) {
			request = request.clone({
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					Authorization: `Bearer ${this.token}`,
				}),
			});
		}

		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					this.loaderSrvc.setLoader(false);
				}
			}),
			catchError((error: any) => {
				if (error instanceof HttpErrorResponse) {
					this.loaderSrvc.setLoader(false);
					return throwError(error);
				}
			})
		);
	}
}
