import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { AllownumberonlyDirective } from './directives/Numberdirective/allownumberonly.directive';
import { NumberCommaDirective } from './directives/Number_Comma/number-comma.directive';


@NgModule({
	imports: [
		CommonModule,
		LayoutModule
	],
	declarations: [AllownumberonlyDirective, NumberCommaDirective],

	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'standard'
			} as MatFormFieldDefaultOptions
		}
	],

	exports: [
		LayoutModule, AllownumberonlyDirective, NumberCommaDirective
	],
})
export class VexModule {
}
