import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'localtime'
})
export class LocaltimePipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): any {
        const date = moment.utc(value, 'YYYY-MM-DD hh:mm:ss A');
        const localDate = date.local().format('lll');
        return localDate;
    }

}
