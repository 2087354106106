export const loginType = [
	{ name: 'Macro User', type: '-1.0' },
	{ name: 'Basic User', type: '0.0' },
	{ name: 'Basic Org Member', type: '0.1' },
	{ name: 'Basic Org', type: '0.5' },
	{ name: 'Pro User', type: '1.0' },
	{ name: 'Pro Competition User', type: '1.0', competitor: '1.0' },
	{ name: 'Pro Org Member', type: '1.1' },
	{ name: 'Pro Org', type: '1.5' },
	{ name: 'Trader User', type: '2.0' },
	{ name: 'Trader Org Member', type: '2.1' },
	{ name: 'Trader Org', type: '2.5' },
	{ name: 'Standard Admin', type: '3.0' },
	{ name: 'Senior Admin', type: '4.0' },
	{ name: 'Super Admin', type: '5.0' },
];
