import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})

/**
 * Not currently required, using DataModelService instead
 */
export class DataService {
	upgrade: BehaviorSubject<boolean>;
	url: BehaviorSubject<string>;

	constructor() {
		this.upgrade = new BehaviorSubject(false);
		this.url = new BehaviorSubject('');
	}

	navigateByNotificationUrl(url) {
		this.url.next(url);
	}

	set_upgrade(res) {
		this.upgrade.next(res);
	}
}
