import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icUploadLocation from '@iconify/icons-ic/twotone-cloud-upload';
import icClose from '@iconify/icons-ic/twotone-close';
import { ApiService } from 'src/shared/api.service';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { AuthService } from 'src/shared/auth.service';

@Component({
	selector: 'dashboard-users-profile-email',
	templateUrl: './dashboard-users-profile-email.component.html',
	styleUrls: ['./dashboard-users-profile-email.component.scss'],
	animations: [
		stagger80ms,
		scaleIn400ms,
		fadeInRight400ms,
		fadeInUp400ms
	]
})
export class DashboardUsersProfileEmailComponent implements OnInit {
	form: FormGroup;
	icClose = icClose;
	icUploadLocation = icUploadLocation;
	visible = false;
	visibleConfirm = false;
	icVisibility = icVisibility;
	icVisibilityOff = icVisibilityOff;
	arr: [];
	email: any;

	constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
		private dialogRef: MatDialogRef<DashboardUsersProfileEmailComponent>,
		private fb: FormBuilder,
		private auth: AuthService
	) { }

	ngOnInit(): void {
		this.form = this.fb.group({
			email: [null, [Validators.required, Validators.email]]
		});

		this.email = this.auth.user()['email']
	}

	send() {
		this.dialogRef.close(this.form.value);
	}
	submitEnter(event) {
		if (event.keyCode === 13) {
			this.send();
		}
	}

}
