<vex-page-layout @stagger>
	<h1 class="mb-3">{{ data['title'] }}</h1>

	<div mat-dialog-content>
		<p>{{ data['message'] }}</p>
	</div>

	<div @fadeInRight class="text-right mt-4">
		<button *ngIf="data['cancelButton'] !== false" class="mr-2" mat-button mat-dialog-close (click)="onDismiss()">NO</button>
		<button
			[color]="data.confirmButtonColor ? data.confirmButtonColor : 'warn'"
			(click)="onConfirm()"
			[class.w-100]="data.cancelButton === false"
			mat-raised-button
		>
			{{ data['confirmButtonText'] }}
		</button>
	</div>
</vex-page-layout>
