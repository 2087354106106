import { Injectable, OnInit } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class LangInterceptor implements HttpInterceptor, OnInit {
	user_id: any;

	constructor(private authSrvc: AuthService) { }

	ngOnInit() { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let modifiedRequest: any = request;

		if (this.authSrvc.user()) {
			const user = this.authSrvc.user();
			this.user_id = user['id'];

			if (modifiedRequest.method === 'POST') {
				if (!(modifiedRequest.body instanceof FormData)) {
					const data = { ...(modifiedRequest.body as object), lang: 'en', user_id: this.user_id };
					modifiedRequest = request.clone({ body: data });
				}
			}

			if (modifiedRequest.method === 'GET') {
				modifiedRequest = request.clone({
					params: (request.params ? request.params : new HttpParams()).set('user_id', this.user_id),
				});
			}
		}
		return next.handle(modifiedRequest);
	}
}
