import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import { ApiService } from 'src/shared/api.service';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/shared/notification.service';

@Component({
	selector: 'vex-toolbar-notifications',
	templateUrl: './toolbar-notifications.component.html',
	styleUrls: ['./toolbar-notifications.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarNotificationsComponent implements OnInit, OnDestroy {
	notificationCount: any;

	@ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

	dropdownOpen: boolean;
	icNotificationsActive = icNotificationsActive;
	notification$: Subscription;
	constructor(private notificationSrvc: NotificationService, private cd: ChangeDetectorRef, private apiSrvc: ApiService) {}

	ngOnInit() {
		this.notification$ = this.apiSrvc.notificationData$.subscribe((res) => {
			this.notificationCount = res.reduce((count, item) => count + (item.read === '0'), 0);
			this.cd.detectChanges();
		});
	}

	showPopover() {
		this.dropdownOpen = true;
		this.cd.markForCheck();

		this.notificationSrvc.showPopover(this.originRef, ToolbarNotificationsDropdownComponent);

		this.notificationSrvc.popoverRef.afterClosed$.subscribe(() => {
			this.dropdownOpen = false;
			this.cd.markForCheck();
		});
	}

	ngOnDestroy(): void {
		this.notification$.unsubscribe();
	}
}
