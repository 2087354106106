import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PageService {
	title = new Subject<string>();
	header = new Subject<string>();
	description = new Subject<string>();
	constructor() {}

	setPageTitle(name: string): void {
		this.title.next(name);
	}

	setPageHeader(name: string): void {
		this.header.next(name);
	}

	setPageDescription(name: string): void {
		this.description.next(name);
	}
}
