import { Injectable } from '@angular/core';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { AuthService } from './auth.service';
import { loginType } from 'src/shared/models/userType';

@Injectable({
	providedIn: 'root',
})
export class SideBarSrvcService {
	constructor(private navigationService: NavigationService, private authSrvc: AuthService) { }

	loadSidebar() {
		let role;

		const user = this.authSrvc.user();
		loginType.forEach((item) => {
			if (user && item.type === user['role'] && !item.competitor && item.competitor !== user['competition_user']) {
				role = item.name;
			} else if (user && item.type === user['role'] && item.competitor && item.competitor === user['competition_user']) {
				role = 'Pro Competition User';
			}
		});

		if (role === 'Super Admin') {

			this.navigationService.items = [
				{
					type: 'link',
					label: 'Market',
					route: 'dashboard/market',
					icon: 'trending_up',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'My Portfolio',
					icon: 'assignment',
					children: [
						{
							type: 'link',
							label: 'My Prices',
							route: 'dashboard/portfolio/prices',
						},
						{
							type: 'link',
							label: 'My Performance',
							route: 'dashboard/portfolio/performance',
						},
						{
							type: 'link',
							label: 'Upload Assets',
							route: 'dashboard/portfolio/upload-assets',
						},
					],
				},
				{
					type: 'link',
					label: 'My Watchlist',
					route: 'dashboard/watchlist',
					icon: 'hotel_class',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'link',
					label: 'Request Index',
					route: 'dashboard/request-index',
					icon: 'receipt',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'Futures Trading',
					icon: 'insights',
					children: [
						{
							type: 'link',
							label: 'Market',
							route: 'dashboard/futures/market',
						},
						{
							type: 'link',
							label: 'Orders',
							route: 'dashboard/futures/my-orders',
						},
						{
							type: 'link',
							label: 'Portfolio',
							route: 'dashboard/futures/portfolio',
						},
					],
				},
				{
					type: 'link',
					label: 'Mobility Economy',
					route: 'dashboard/mobility-economy',
					icon: 'bolt',
					routerLinkActiveOptions: { exact: true },
				},

				{
					type: 'dropdown',
					label: 'Manage',
					icon: 'manage_accounts',
					children: [
						{
							type: 'link',
							label: 'Users',
							route: 'dashboard/manage-users',
						},
						{
							type: 'link',
							label: 'Indexes',
							route: 'dashboard/manage-indexes',
						},
						{
							type: 'link',
							label: 'Assets',
							route: 'dashboard/manage-assets',
						},
						{
							type: 'link',
							label: 'Vehicles',
							route: 'dashboard/vehicles',
						},
						{
							type: 'link',
							label: 'Futures Products',
							route: 'dashboard/manage/future-products',
						},
						{
							type: 'link',
							label: 'Futures Orders',
							route: 'dashboard/manage/future-orders',
						},
						{
							type: 'link',
							label: 'Competitions',
							route: 'dashboard/manage-competitions',
						},
						{
							type: 'link',
							label: 'Logs',
							route: 'dashboard/logs',
						},
					],
				},
			];
		} else if (role === 'Senior Admin') {
			this.navigationService.items = [
				{
					type: 'link',
					label: 'Market',
					route: 'dashboard/market',
					icon: 'trending_up',
				},
				{
					type: 'dropdown',
					label: 'My Portfolio',
					icon: 'assignment',
					children: [
						{
							type: 'link',
							label: 'My Prices',
							route: 'dashboard/portfolio/prices',
						},
						{
							type: 'link',
							label: 'My Performance',
							route: 'dashboard/portfolio/performance',
						},
						{
							type: 'link',
							label: 'Upload Assets',
							route: 'dashboard/portfolio/upload-assets',
						},
					],
				},

				{
					type: 'link',
					label: 'My Watchlist',
					route: 'dashboard/watchlist',
					icon: 'hotel_class',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'link',
					label: 'Request Index',
					route: 'dashboard/request-index',
					icon: 'receipt',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'Futures Trading',
					icon: 'insights',
					children: [
						{
							type: 'link',
							label: 'Market',
							route: 'dashboard/futures/market',
						},
						{
							type: 'link',
							label: 'Orders',
							route: 'dashboard/futures/my-orders',
						},
						{
							type: 'link',
							label: 'Portfolio',
							route: 'dashboard/futures/portfolio',
						},
					],
				},

				{
					type: 'link',
					label: 'Mobility Economy',
					route: 'dashboard/mobility-economy',
					icon: 'bolt',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'Manage',
					icon: 'manage_accounts',
					children: [
						{
							type: 'link',
							label: 'Users',
							route: 'dashboard/manage-users',
						},
						{
							type: 'link',
							label: 'Indexes',
							route: 'dashboard/manage-indexes',
						},
						{
							type: 'link',
							label: 'Assets',
							route: 'dashboard/manage-assets',
						},
						{
							type: 'link',
							label: 'Vehicles',
							route: 'dashboard/vehicles',
						},
						{
							type: 'link',
							label: 'Futures Products',
							route: 'dashboard/manage/future-products',
						},
						{
							type: 'link',
							label: 'Futures Orders',
							route: 'dashboard/manage/future-orders',
						},
						{
							type: 'link',
							label: 'Competitions',
							route: 'dashboard/manage-competitions',
						},
					],
				},
			];
		} else if (role === 'Standard Admin') {
			this.navigationService.items = [
				{
					type: 'link',
					label: 'Market',
					route: 'dashboard/market',
					icon: 'trending_up',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'My Portfolio',
					icon: 'assignment',
					children: [
						{
							type: 'link',
							label: 'My Prices',
							route: 'dashboard/portfolio/prices',
						},
						{
							type: 'link',
							label: 'My Performance',
							route: 'dashboard/portfolio/performance',
						},
						{
							type: 'link',
							label: 'Upload Assets',
							route: 'dashboard/portfolio/upload-assets',
						},
					],
				},

				{
					type: 'link',
					label: 'My Watchlist',
					route: 'dashboard/watchlist',
					icon: 'hotel_class',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'link',
					label: 'Request Index',
					route: 'dashboard/request-index',
					icon: 'receipt',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'Futures Trading',
					icon: 'insights',
					children: [
						{
							type: 'link',
							label: 'Market',
							route: 'dashboard/futures/market',
						},
						{
							type: 'link',
							label: 'Orders',
							route: 'dashboard/futures/my-orders',
						},
						{
							type: 'link',
							label: 'Portfolio',
							route: 'dashboard/futures/portfolio',
						},
					],
				},
				{
					type: 'link',
					label: 'Mobility Economy',
					route: 'dashboard/mobility-economy',
					icon: 'bolt',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'Manage',
					icon: 'manage_accounts',
					children: [
						{
							type: 'link',
							label: 'Users',
							route: 'dashboard/manage-users',
						},
						{
							type: 'link',
							label: 'Indexes',
							route: 'dashboard/manage-indexes',
						},
						{
							type: 'link',
							label: 'Assets',
							route: 'dashboard/manage-assets',
						},
						{
							type: 'link',
							label: 'Futures Products',
							route: 'dashboard/manage/future-products',
						},
						{
							type: 'link',
							label: 'Futures Orders',
							route: 'dashboard/manage/future-orders',
						},
						{
							type: 'link',
							label: 'Competitions',
							route: 'dashboard/manage-competitions',
						},
					],
				},
			];
		} else if (role === 'Trader User' || role === 'Trader Org' || role === 'Trader Org Member') {
			this.navigationService.items = [
				{
					type: 'link',
					label: 'Market',
					route: 'dashboard/market',
					icon: 'trending_up',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'My Portfolio',
					icon: 'assignment',
					children: [
						{
							type: 'link',
							label: 'My Prices',
							route: 'dashboard/portfolio/prices',
						},
						{
							type: 'link',
							label: 'My Performance',
							route: 'dashboard/portfolio/performance',
						},
						{
							type: 'link',
							label: 'Upload Assets',
							route: 'dashboard/portfolio/upload-assets',
						},
					],
				},
				{
					type: 'link',
					label: 'My Watchlist',
					route: 'dashboard/watchlist',
					icon: 'hotel_class',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'link',
					label: 'Request Index',
					route: 'dashboard/request-index',
					icon: 'receipt',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'Futures Trading',
					icon: 'insights',
					children: [
						{
							type: 'link',
							label: 'Market',
							route: 'dashboard/futures/market',
						},
						{
							type: 'link',
							label: 'Orders',
							route: 'dashboard/futures/my-orders',
						},
						{
							type: 'link',
							label: 'Portfolio',
							route: 'dashboard/futures/portfolio',
						},
					],
				},

				{
					type: 'link',
					label: 'Mobility Economy',
					route: 'dashboard/mobility-economy',
					icon: 'bolt',
					routerLinkActiveOptions: { exact: true },
				},
			];
		} else if (role === 'Pro User' || role === 'Pro Org' || role === 'Pro Org Member') {
			this.navigationService.items = [
				{
					type: 'link',
					label: 'Market',
					route: 'dashboard/market',
					icon: 'trending_up',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'My Portfolio',
					icon: 'assignment',
					children: [
						{
							type: 'link',
							label: 'My Prices',
							route: 'dashboard/portfolio/prices',
						},
						{
							type: 'link',
							label: 'My Performance',
							route: 'dashboard/portfolio/performance',
						},
						{
							type: 'link',
							label: 'Upload Assets',
							route: 'dashboard/portfolio/upload-assets',
						},
					],
				},
				{
					type: 'link',
					label: 'My Watchlist',
					route: 'dashboard/watchlist',
					icon: 'hotel_class',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'link',
					label: 'Request Index',
					route: 'dashboard/request-index',
					icon: 'receipt',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'Futures Trading',
					icon: 'insights',
					children: [
						{
							type: 'link',
							label: 'Market',
							route: 'dashboard/futures/market',
						},
						{
							type: 'link',
							label: 'Orders',
							route: 'dashboard/futures/my-orders',
						},
						{
							type: 'link',
							label: 'Portfolio',
							route: 'dashboard/futures/portfolio',
						},
					],
				},
				{
					type: 'link',
					label: 'Mobility Economy',
					route: 'dashboard/mobility-economy',
					icon: 'bolt',
					routerLinkActiveOptions: { exact: true },
				},
			];
		} else if (role === 'Pro Competition User') {
			this.navigationService.items = [
				{
					type: 'link',
					label: 'Market',
					route: 'dashboard/market',
					icon: 'trending_up',
					routerLinkActiveOptions: { exact: true },
				},

				{
					type: 'link',
					label: 'My Watchlist',
					route: 'dashboard/watchlist',
					icon: 'hotel_class',
					routerLinkActiveOptions: { exact: true },
				},

				{
					type: 'dropdown',
					label: 'Futures Trading',
					icon: 'insights',
					children: [
						{
							type: 'link',
							label: 'Market',
							route: 'dashboard/futures/market',
						},

						{
							type: 'link',
							label: 'Orders',
							route: 'dashboard/futures/my-orders',
						},

						{
							type: 'link',
							label: 'Portfolio',
							route: 'dashboard/futures/portfolio',
						},

						{
							type: 'link',
							label: 'Leaderboard',
							route: 'dashboard/futures/leaderboard',
						},
					],
				},
				{
					type: 'link',
					label: 'Mobility Economy',
					route: 'dashboard/mobility-economy',
					icon: 'bolt',
					routerLinkActiveOptions: { exact: true },
				},
			];
		} else if (role === 'Basic User' || role === 'Basic Org' || role === 'Basic Org Member') {
			this.navigationService.items = [
				{
					type: 'link',
					label: 'Market',
					route: 'dashboard/market',
					icon: 'trending_up',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'My Portfolio',
					icon: 'assignment',
					children: [
						{
							type: 'link',
							label: 'My Prices',
							need_upgrade: true,
							route: 'dashboard/portfolio/prices',
						},
						{
							type: 'link',
							label: 'My Performance',
							need_upgrade: true,
							route: 'dashboard/portfolio/performance',
						},
						{
							type: 'link',
							label: 'Upload Assets',
							need_upgrade: true,
							route: 'dashboard/portfolio/upload-assets',
						},
					],
				},
				{
					type: 'link',
					label: 'My Watchlist',
					route: 'dashboard/watchlist',
					icon: 'hotel_class',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'link',
					label: 'Request Index',
					need_upgrade: true,
					route: 'dashboard/request-index',
					icon: 'receipt',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'Futures Trading',
					icon: 'insights',
					children: [
						{
							type: 'link',
							label: 'Market',
							need_upgrade: true,
							route: 'dashboard/futures/market',
						},
						{
							type: 'link',
							label: 'Orders',
							need_upgrade: true,
							route: 'dashboard/futures/my-orders',
						},
						{
							type: 'link',
							label: 'Portfolio',
							need_upgrade: true,
							route: 'dashboard/futures/portfolio',
						},
					],
				},
				{
					type: 'link',
					label: 'Mobility Economy',
					route: 'dashboard/mobility-economy',
					icon: 'bolt',
					routerLinkActiveOptions: { exact: true },
				},
			];
		} else if (role === 'Macro User') {
			this.navigationService.items = [
				{
					type: 'link',
					label: 'Market',
					route: 'dashboard/market',
					icon: 'trending_up',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'My Portfolio',
					icon: 'assignment',
					children: [
						{
							type: 'link',
							label: 'My Prices',
							need_upgrade: true,
							route: 'dashboard/portfolio/prices',
						},
						{
							type: 'link',
							label: 'My Performance',
							need_upgrade: true,
							route: 'dashboard/portfolio/performance',
						},
						{
							type: 'link',
							label: 'Upload Assets',
							need_upgrade: true,
							route: 'dashboard/portfolio/upload-assets',
						},
					],
				},
				{
					type: 'link',
					label: 'My Watchlist',
					route: 'dashboard/watchlist',
					icon: 'hotel_class',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'link',
					label: 'Request Index',
					need_upgrade: true,
					route: 'dashboard/request-index',
					icon: 'receipt',
					routerLinkActiveOptions: { exact: true },
				},
				{
					type: 'dropdown',
					label: 'Futures Trading',
					icon: 'insights',
					children: [
						{
							type: 'link',
							label: 'Market',
							need_upgrade: true,
							route: 'dashboard/futures/market',
						},
						{
							type: 'link',
							label: 'Orders',
							need_upgrade: true,
							route: 'dashboard/futures/my-orders',
						},
						{
							type: 'link',
							label: 'Portfolio',
							need_upgrade: true,
							route: 'dashboard/futures/portfolio',
						},
					],
				},
				{
					type: 'link',
					label: 'Mobility Economy',
					route: 'dashboard/mobility-economy',
					icon: 'bolt',
					routerLinkActiveOptions: { exact: true },
				},
			];
		}
	}
}
