import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/shared/api.service';
import { AuthService } from 'src/shared/auth.service';

@Injectable({
	providedIn: 'root'
})
export class LayoutService {
	userID: any;

	public notifcationSubject = new BehaviorSubject<any>(0);

	private _quickpanelOpenSubject = new BehaviorSubject<boolean>(false);
	quickpanelOpen$ = this._quickpanelOpenSubject.asObservable();

	private _sidenavOpenSubject = new BehaviorSubject<boolean>(false);
	sidenavOpen$ = this._sidenavOpenSubject.asObservable();

	private _sidenavCollapsedSubject = new BehaviorSubject<boolean>(false);
	sidenavCollapsed$ = this._sidenavCollapsedSubject.asObservable();

	private _sidenavCollapsedOpenSubject = new BehaviorSubject<boolean>(false);
	sidenavCollapsedOpen$ = this._sidenavCollapsedOpenSubject.asObservable();

	private _configpanelOpenSubject = new BehaviorSubject<boolean>(false);
	configpanelOpen$ = this._configpanelOpenSubject.asObservable();

	private _searchOpen = new BehaviorSubject<boolean>(false);
	searchOpen$ = this._searchOpen.asObservable();

	// gt = Greater Than
	// lt = Less Than

	gtDesktop$ = this.breakpointObserver.observe(`(min-width: 1280px)`).pipe(
		map(state => state.matches)
	);
	ltDesktop$ = this.breakpointObserver.observe(`(max-width: 1279px)`).pipe(
		map(state => state.matches)
	);
	gtTablet$ = this.breakpointObserver.observe(`(min-width: 960px)`).pipe(
		map(state => state.matches)
	);
	ltTablet$ = this.breakpointObserver.observe(`(max-width: 959px)`).pipe(
		map(state => state.matches)
	);
	gtMobile$ = this.breakpointObserver.observe(`(min-width: 600px)`).pipe(
		map(state => state.matches)
	);
	ltMobile$ = this.breakpointObserver.observe(`(max-width: 599px)`).pipe(
		map(state => state.matches)
	);

	// isDesktop = () => this.breakpointObserver.isMatched(`(min-width: 1280px)`);
	// isLtLg = () => this.breakpointObserver.isMatched(`(max-width: 1279px)`);
	// isMobile = () => this.breakpointObserver.isMatched(`(max-width: 599px)`);

	constructor(private router: Router,
		private breakpointObserver: BreakpointObserver,
		private httpClient: HttpClient,
		private authSrvc: AuthService
	) {
		this.userID = this.authSrvc.user() ? this.authSrvc.user()['userID'] : '';
	}

	openQuickpanel() {
		this._quickpanelOpenSubject.next(true);
	}

	closeQuickpanel() {
		this._quickpanelOpenSubject.next(false);
	}

	openSidenav() {
		this._sidenavOpenSubject.next(true);
	}

	closeSidenav() {
		this._sidenavOpenSubject.next(false);
	}

	collapseSidenav() {
		this._sidenavCollapsedSubject.next(true);
	}

	expandSidenav() {
		this._sidenavCollapsedSubject.next(false);
	}

	collapseOpenSidenav() {
		this._sidenavCollapsedOpenSubject.next(true);
	}

	collapseCloseSidenav() {
		this._sidenavCollapsedOpenSubject.next(false);
	}

	openConfigpanel() {
		this._configpanelOpenSubject.next(true);
	}

	closeConfigpanel() {
		this._configpanelOpenSubject.next(false);
	}

	openSearch() {
		this._searchOpen.next(true);
	}

	closeSearch() {
		this._searchOpen.next(false);
	}

	enableRTL() {
		this.router.navigate([], {
			queryParams: {
				rtl: 'true'
			}
		});
	}

	disableRTL() {
		this.router.navigate([], {
			queryParams: {
				rtl: 'false'
			}
		});
	}

	readAllNotification(body) {
		return this.httpClient.post(`${environment.api_url}readNotification`, body);
	}

	// getNotification() {
	//   this.layserv.getAllNotifications().subscribe((data: any) => {
	//     this.layserv.notifcationSubject.next(data.unreadCount)
	//     this.layserv.notifcationSubject.subscribe((data: any) => {
	//       this.notificationCount = data;
	//     })
	//   })
	// }
}
