import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { MatDialog } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/shared/api.service';
import { DashboardUsersProfileEmailComponent } from 'src/app/dashboard/dashboard-users-profile/dashboard-users-profile-email/dashboard-users-profile-email.component';
import { AuthService } from 'src/shared/auth.service';
import { ConfirmationDialogComponent } from 'src/shared/confirmation-dialog/confirmation-dialog.component';
import { DashboardUsersProfilePasswordComponent } from 'src/app/dashboard/dashboard-users-profile/dashboard-users-profile-password/dashboard-users-profile-password.component';

export interface OnlineStatus {
	id: 'online' | 'away' | 'dnd' | 'offline';
	label: string;
	icon: string;
	colorClass: string;
}

@Component({
	selector: 'vex-toolbar-user-dropdown',
	templateUrl: './toolbar-user-dropdown.component.html',
	styleUrls: ['./toolbar-user-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserDropdownComponent implements OnInit {
	first_name: any;
	last_name: any;
	id: any;
	email: any;
	role: number = this.authSrvc.user() ? this.authSrvc.user()['role'] : 0;

	items: MenuItem[] = [
		{
			id: '1',
			icon: 'account_circle',
			label: 'My Profile',
			description: 'Personal information',
			colorClass: 'text-teal',
			route: '/dashboard/users/profile',
		},
		{
			id: '2',
			icon: 'work',
			label: 'My Organization',
			description: 'Manage your organization',
			colorClass: 'text-teal',
			route: '/dashboard/users/organization',
		},
		{
			id: '4',
			icon: 'account_balance_wallet',
			label: 'My Billing',
			description: 'Billing information',
			colorClass: 'text-teal',
			route: '/dashboard/billing/',
		},
	];

	modalList: MenuItem[] = [
		{
			id: '1',
			icon: 'https',
			label: 'Change Password',
			description: 'Set a new password',
			colorClass: 'text-teal',
			route: '/dashboard',
		},
		{
			id: '2',
			icon: 'mail',
			label: 'Change Email',
			description: 'Set a new email',
			colorClass: 'text-teal',
			route: '/dashboard',
		},
	];

	statuses: OnlineStatus[] = [
		{
			id: 'online',
			label: 'Online',
			icon: 'check_circle',
			colorClass: 'text-green',
		},
		{
			id: 'away',
			label: 'Away',
			icon: 'access_time_filled',
			colorClass: 'text-orange',
		},
		{
			id: 'dnd',
			label: 'Do not disturb',
			icon: 'do_not_disturb_on',
			colorClass: 'text-red',
		},
		{
			id: 'offline',
			label: 'Offline',
			icon: 'offline_bolt',
			colorClass: 'text-gray',
		},
	];

	activeStatus: OnlineStatus = this.statuses[0];

	trackById = trackById;

	constructor(
		private cd: ChangeDetectorRef,
		private auth: ApiService,
		private matdialog: MatDialog,
		private userService: ApiService,
		private snackbar: MatSnackBar,
		private authService: AuthService,
		private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
		private authSrvc: AuthService
	) {}

	arrayInsertAt(array, index, ...elements) {
		var updatedArray = array.splice(index, 0, ...elements);
		return updatedArray;
	}

	ngOnInit() {
		this.id = this.authService.user()['id'];
		this.first_name = this.authService.user()['first_name'];
		this.last_name = this.authService.user()['last_name'];
		this.email = this.authService.user()['email'];
	}

	setStatus(status: OnlineStatus) {
		this.activeStatus = status;
		this.cd.markForCheck();
	}

	close() {
		this.popoverRef.close();
	}
	openDialog(type) {
		if (type === 'Change Password') {
			const dialogRef = this.matdialog.open(DashboardUsersProfilePasswordComponent, {
				width: '524px',
				maxHeight: '95vh',
				disableClose: true,
			});
		} else if (type === 'Change Email') {
			const dialogRef = this.matdialog.open(DashboardUsersProfileEmailComponent, {
				width: '524px',
				maxHeight: '95vh',
				disableClose: true,
			});
			dialogRef.afterClosed().subscribe((data) => {
				if (data) {
					const body = {
						lang: 'en',
						new_email: data.email,
					};

					this.userService.changeEmail(body, this.id).subscribe((res: any) => {
						if (res.status === '1') {
							return this.snackbar.open('Verify link sent to your email', '', { duration: 5000 });
						} else {
							this.snackbar.open(res.message, '', { duration: 5000 });
						}
					});
				}
			});
		}
	}

	logout() {
		this.matdialog
			.open(ConfirmationDialogComponent, {
				data: { message: 'Are you sure you want to logout?' },
			})
			.afterClosed()
			.subscribe((result: any) => {
				if (result) {
					this.popoverRef.close();
					this.auth.logout();
				}
			});
	}
}
