import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class SnackService {
	constructor(private snackBar: MatSnackBar) {}

	open(message: string, time: number = 5000, action: string = '') {
		{
			this.snackBar.open(message, action, {
				duration: time,
				direction: 'ltr',
			});
		}
	}
}
