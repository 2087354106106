import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import { AuthService } from 'src/shared/auth.service';

@Component({
	selector: 'vex-toolbar-user',
	templateUrl: './toolbar-user.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {
	userName = '';
	dropdownOpen: boolean;
	icPerson = icPerson;

	constructor(
		private popover: PopoverService,
		private cd: ChangeDetectorRef,
		private authSrvc: AuthService
	) { }

	ngOnInit() {

		if (this.authSrvc.user()) {
			const user = this.authSrvc.user();
			this.userName = user['name'];
		}
	}

	showPopover(originRef: HTMLElement) {
		this.dropdownOpen = true;
		this.cd.markForCheck();

		const popoverRef = this.popover.open({
			content: ToolbarUserDropdownComponent,
			origin: originRef,
			offsetY: 12,
			position: [
				{
					originX: 'center',
					originY: 'top',
					overlayX: 'center',
					overlayY: 'bottom'
				},
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top',
				},
			]
		});

		popoverRef.afterClosed$.subscribe(() => {
			this.dropdownOpen = false;
			this.cd.markForCheck();
		});
	}
}
