import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from '../loader.service';

@Component({
	selector: 'dashboard-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

	isLoading = false;

	constructor(private loaderSrvc: LoaderService, private cdr: ChangeDetectorRef) { }

	ngOnInit(): void {
		this.loaderSrvc.loader
			.subscribe(res => {
				this.isLoading = res;
				this.cdr.detectChanges();
			})
	}
}
