import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoaderService {

	loader = new Subject<boolean>();
	constructor() { }

	setLoader(status: boolean): void {
		this.loader.next(status);
	}
}